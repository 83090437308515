import { DocumentNode } from '@apollo/client';
import { SolutionDomain } from '@energiebespaarders/constants';
import { isUserlessSession } from '~/utils/isUserlessSession';
import {
  default as savingsCheckMutations,
  SET_EXPERIMENT_METADATA,
  UPDATE_MOTIVATION,
} from './mutations';
import userlessMutations from './mutationsUserless';
import bathImg from '/public/img/illustrations/cutouts/bathtub.svg'; /* original path: '/public/img/illustrations/cutouts/bathtub.svg'; */
import roofImg from '/public/img/illustrations/cutouts/dakisolatie.jpg'; /* original path: '/public/img/illustrations/cutouts/dakisolatie.jpg'; */
import dishWasherImg from '/public/img/illustrations/cutouts/dishwasher.svg'; /* original path: '/public/img/illustrations/cutouts/dishwasher.svg'; */
import occupantsImg from '/public/img/illustrations/cutouts/huishouden.png'; /* original path: '/public/img/illustrations/cutouts/huishouden.png'; */
import glazingImg from '/public/img/illustrations/cutouts/isolatieglas.jpg'; /* original path: '/public/img/illustrations/cutouts/isolatieglas.jpg'; */
import weatherStripImg from '/public/img/illustrations/cutouts/kierdichting.jpg'; /* original path: '/public/img/illustrations/cutouts/kierdichting.jpg'; */
import wallImg from '/public/img/illustrations/cutouts/spouwmuurisolatie.jpg'; /* original path: '/public/img/illustrations/cutouts/spouwmuurisolatie.jpg'; */
import boilerImg from '/public/img/illustrations/cutouts/verwarming.jpg'; /* original path: '/public/img/illustrations/cutouts/verwarming.jpg'; */
import floorImg from '/public/img/illustrations/cutouts/vloerisolatie.jpg'; /* original path: '/public/img/illustrations/cutouts/vloerisolatie.jpg'; */
import windmillImg from '/public/img/illustrations/cutouts/windmill.svg'; /* original path: '/public/img/illustrations/cutouts/windmill.svg'; */
import pvImg from '/public/img/illustrations/cutouts/zonnepanelen.jpg'; /* original path: '/public/img/illustrations/cutouts/zonnepanelen.jpg'; */

export const questionDomainDict: Partial<Record<QuestionKey, SolutionDomain | ''>> = {
  hasWallCavity: SolutionDomain.WallInsulation,
  hasWallInsulation: SolutionDomain.WallInsulation,
  hasWallInsulationFollowUp: SolutionDomain.WallInsulation,
  hasExtraWallInsulation: SolutionDomain.WallInsulation,
  hasCrawlspace: SolutionDomain.FloorInsulation,
  hasFloorInsulation: SolutionDomain.FloorInsulation,
  hasFloorInsulationFollowUp: SolutionDomain.FloorInsulation,
  hasExtraFloorInsulation: SolutionDomain.FloorInsulation,
  hasRoofInsulation: SolutionDomain.RoofInsulation,
  hasRoofInsulationFollowUp: SolutionDomain.RoofInsulation,
  hasExtraRoofInsulation: SolutionDomain.RoofInsulation,
  livingGlassType: SolutionDomain.InsulationGlazing,
  sleepingGlassType: SolutionDomain.InsulationGlazing,
  panelAmount: SolutionDomain.PvSystem,
  boilerAge: SolutionDomain.Heating,
};

export type QuestionKey =
  | 'address'
  | 'isVve'
  | 'isCurrentOccupant'
  | 'apartmentProperties'
  | 'hasWallCavity'
  | 'hasWallInsulation'
  | 'hasExtraWallInsulation'
  | 'hasWallInsulationFollowUp'
  | 'hasCrawlspace'
  | 'hasFloorInsulation'
  | 'hasFloorInsulationFollowUp'
  | 'hasExtraFloorInsulation'
  | 'hasRoofInsulation'
  | 'hasExtraRoofInsulation'
  | 'hasRoofInsulationFollowUp'
  | 'livingGlassType'
  | 'sleepingGlassType'
  | 'panelAmount'
  | 'hasDishWasher'
  | 'boilerAge'
  | 'hasWeatherStripping'
  | 'hasBath'
  | 'hasDishWasher'
  | 'residentAmount'
  | 'slurpers'
  | 'houseConsumption'
  | 'motivation'
  | 'greenEnergyContract'
  | 'referrer'
  | 'final'
  | 'energyPrices';

export interface IConsumption {
  gas: number;
  electricity: number;
}

export enum CardType {
  ADDRESS = 'address',
  STRING = 'string',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  BOOLEAN = 'boolean',
  RANGE = 'slider',
  CONSUMPTION = 'consumption', // exception for 2 inputs
  ENERGY_PRICE = 'energyPrices',
  FINAL = 'final',
}
export type BCAnswerValue = boolean | string | number | string[] | null | IConsumption;
export type BCAnswer = { label: string; value: BCAnswerValue };
export interface BCQuestion {
  key: QuestionKey;
  type: CardType;
  image?: string;
  question?: string;
  partnerQuestion?: string;
  placeholder?: string;
  explainer?: string;
  answerOptions?: BCAnswer[];
  valueMapper?: (input: any) => any; // Maps values to options (for checkboxes etc.)
  mutation?: DocumentNode;
  hasExtraInfo?: boolean;
  rangeOptions?: {
    min?: number;
    max?: number;
    step?: number;
  };
  prefix?: string;
  suffix?: string;
  mutationVariable?: string;
  refetchEnergy?: boolean;
}

const getMutation = (name: keyof typeof userlessMutations, useUserless = isUserlessSession()) => {
  if (useUserless) return userlessMutations[name];
  return savingsCheckMutations[name];
};

const bcCards: BCQuestion[] = [
  {
    key: 'address',
    type: CardType.ADDRESS,
  },
  {
    key: 'isVve',
    question: 'Is de woning onderdeel van een VVE?',
    placeholder: 'VVE',
    type: CardType.RADIO,
    mutation: getMutation('UPDATE_HOUSE_IS_VVE'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
    ],
  },
  {
    key: 'isCurrentOccupant',
    question: 'Woon je al in de woning?',
    partnerQuestion: 'Woont de klant al in de woning?',
    placeholder: 'Huidige bewoner',
    type: CardType.RADIO,
    image: occupantsImg,
    mutation: getMutation('UPDATE_CURRENT_OCCUPANT'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
    ],
  },
  {
    key: 'apartmentProperties',
    question: 'Welke eigenschappen heeft het appartement?',
    explainer: 'Dit helpt bepalen welke oplossingen mogelijk zijn',
    type: CardType.CHECKBOX,
    answerOptions: [
      { label: 'Meerdere verdiepingen', value: 'hasMultipleStories' },
      { label: 'Op de bovenste verdieping(en)', value: 'hasRoof' },
      { label: 'Op begane grond', value: 'isOnGroundFloor' },
      { label: 'Op de hoek', value: 'isOnCorner' },
    ],
    mutation: getMutation('UPDATE_APARTMENT_TYPE'),
    refetchEnergy: true,
  },
  {
    key: 'hasWallCavity',
    question: 'Heeft de woning een spouwmuur?',
    explainer: 'Spouwmuren werden pas vanaf 1920 regelmatig bij de bouw toegepast',
    hasExtraInfo: true,
    type: CardType.RADIO,
    image: wallImg,
    mutation: getMutation('UPDATE_HOUSE_WALL_CAVITY'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasWallInsulation',
    question: 'Zijn de spouwmuren al geïsoleerd?',
    explainer: 'Spouwmuurisolatie werd pas vanaf 1975 bij de bouw toegepast',
    hasExtraInfo: true,
    type: CardType.RADIO,
    image: wallImg,
    mutation: getMutation('UPDATE_HOUSE_WALL_INSULATION'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasWallInsulationFollowUp',
    mutationVariable: 'hasExtraWallInsulation',
    question: 'Is de huidige spouwmuurisolatie van zeer goede kwaliteit?',
    explainer: 'Hiermee gaan we uit van een toekomstbestendige isolatiewaarde',
    type: CardType.RADIO,
    image: wallImg,
    mutation: getMutation('UPDATE_HOUSE_WALL_INSULATION_EXTRA'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasExtraWallInsulation',
    question: 'Zijn de muren na de bouw extra geïsoleerd?',
    explainer:
      'Je muren zijn bij de bouw al geïsoleerd. Met extra isolatie gaan we uit van een toekomstbestendige isolatiewaarde',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: wallImg,
    mutation: getMutation('UPDATE_HOUSE_WALL_INSULATION_EXTRA'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasCrawlspace',
    question: 'Heeft de woning een kruipruimte?',
    explainer: 'Kruipruimtes werden pas vanaf 1920 standaard bij de bouw toegepast',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: floorImg,
    mutation: getMutation('UPDATE_HOUSE_HAS_CRAWLSPACE'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasFloorInsulation',
    question: 'Heeft de woning al vloerisolatie?',
    explainer: 'Vloerisolatie werd pas vanaf 1984 standaard bij de bouw toegepast',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: floorImg,
    mutation: getMutation('UPDATE_HOUSE_FLOOR_INSULATION'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasFloorInsulationFollowUp',
    mutationVariable: 'hasExtraFloorInsulation',
    question: 'Is de huidige vloerisolatie van zeer goede kwaliteit?',
    explainer: 'Hiermee gaan we uit van een toekomstbestendige isolatiewaarde',
    type: CardType.RADIO,
    image: floorImg,
    mutation: getMutation('UPDATE_HOUSE_FLOOR_INSULATION_EXTRA'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
  },
  {
    key: 'hasExtraFloorInsulation',
    question: 'Is de vloer na de bouw extra geïsoleerd?',
    explainer: 'Hiermee gaan we uit van een toekomstbestendige isolatiewaarde',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: floorImg,
    mutation: getMutation('UPDATE_HOUSE_FLOOR_INSULATION_EXTRA'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasRoofInsulation',
    question: 'Heeft de woning al dakisolatie?',
    explainer: 'Dakisolatie werd pas vanaf 1975 standaard bij de bouw toegepast',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: roofImg,
    mutation: getMutation('UPDATE_HOUSE_ROOF_INSULATION'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasRoofInsulationFollowUp',
    mutationVariable: 'hasExtraRoofInsulation',
    question: 'Is de huidige dakisolatie van zeer goede kwaliteit?',
    explainer: 'Hiermee gaan we uit van een toekomstbestendige isolatiewaarde',
    type: CardType.RADIO,
    image: roofImg,
    mutation: getMutation('UPDATE_HOUSE_ROOF_INSULATION_EXTRA'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasExtraRoofInsulation',
    question: 'Is het dak na de bouw extra geïsoleerd?',
    explainer: 'Hiermee gaan we uit van een toekomstbestendige isolatiewaarde',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: roofImg,
    mutation: getMutation('UPDATE_HOUSE_ROOF_INSULATION_EXTRA'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'livingGlassType',
    question: 'Welk type glas is er in de woonruimte(s)?',
    explainer: 'Geef het laagste type aan in het geval er verschillende woonruimtes zijn',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: glazingImg,
    mutation: getMutation('UPDATE_HOUSE_LIVING_GLAZING'),
    answerOptions: [
      {
        label: 'Enkel glas',
        value: 'single',
      },
      {
        label: 'Dubbel glas',
        value: 'double',
      },
      {
        label: 'HR-glas',
        value: 'hr',
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'sleepingGlassType',
    question: 'Welk type glas is er in de slaapruimte(s)?',
    explainer: 'Geef het laagste type aan in het geval er verschillende slaapruimtes zijn',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: glazingImg,
    mutation: getMutation('UPDATE_HOUSE_SLEEPING_GLAZING'),
    answerOptions: [
      {
        label: 'Enkel glas',
        value: 'single',
      },
      {
        label: 'Dubbel glas',
        value: 'double',
      },
      {
        label: 'HR-glas',
        value: 'hr',
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'panelAmount',
    question: 'Hoeveel zonnepanelen liggen er op het dak?',
    placeholder: 'Aantal zonnepanelen',
    type: CardType.RANGE,
    hasExtraInfo: true,
    image: pvImg,
    rangeOptions: {
      max: 30,
      min: 0,
      step: 1,
    },
    mutation: getMutation('UPDATE_HOUSE_PV_SYSTEM'),
    refetchEnergy: true,
  },
  {
    key: 'boilerAge',
    question: 'Hoe oud is de CV-ketel?',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: boilerImg,
    answerOptions: [
      {
        label: '0 jaar',
        value: 0,
      },
      {
        label: '1-5 jaar',
        value: 5,
      },
      {
        label: '6-10 jaar',
        value: 10,
      },
      {
        label: '11+ jaar',
        value: 15,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
      {
        label: 'Er is geen CV-ketel',
        value: -1,
      },
    ],
    valueMapper: (value: number) => {
      if (value === null) return null;
      if (value < 0) return -1;
      if (value < 5) return 0;
      if (value < 10) return 5;
      if (value < 15) return 10;
      return 15;
    },
    mutation: getMutation('UPDATE_HOUSE_BOILER_AGE'),
  },
  {
    key: 'hasWeatherStripping',
    question: 'Zijn de naden en kieren al gedicht?',
    explainer: 'Dit gebeurt vaak met strips die zelf worden aangebracht',
    type: CardType.RADIO,
    hasExtraInfo: true,
    image: weatherStripImg,
    mutation: getMutation('UPDATE_HOUSE_WEATHER_STRIPPING'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
    ],
  },
  {
    key: 'hasBath',
    question: 'Heb je thuis een bad?',
    partnerQuestion: 'Is er een bad?',
    type: CardType.RADIO,
    image: bathImg,
    mutation: getMutation('UPDATE_HOUSE_HAS_BATH'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'hasDishWasher',
    question: 'Heb je thuis een vaatwasser?',
    partnerQuestion: 'Is er een vaatwasser?',
    type: CardType.RADIO,
    image: dishWasherImg,
    mutation: getMutation('UPDATE_HOUSE_HAS_DISHWASHER'),
    answerOptions: [
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Nee',
        value: false,
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'residentAmount',
    question: 'Hoeveel bewoners zijn er?',
    partnerQuestion: 'Hoeveel bewoners zijn er (of komen er te wonen)?',
    explainer: 'Hiermee kunnen we een betere inschatting maken van het energieverbruik',
    type: CardType.RADIO,
    image: occupantsImg,
    mutation: getMutation('UPDATE_RESIDENT_AMOUNT'),
    answerOptions: [
      {
        label: '1',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        label: '4',
        value: 4,
      },
      {
        label: '5',
        value: 5,
      },
      {
        label: '6+',
        value: 6,
      },
    ],
  },
  {
    key: 'slurpers',
    question: 'Heb je één of meer van de onderstaande producten?',
    partnerQuestion: 'Zijn er één of meer van de onderstaande producten?',
    explainer: 'Deze worden meegenomen in onze inschattingen',
    type: CardType.CHECKBOX,
    mutation: getMutation('UPDATE_HOUSE_SLURPERS'),
    answerOptions: [
      {
        label: 'Warmtepomp',
        value: 'heatPump',
      },
      {
        label: 'Elektrische auto',
        value: 'electricCar',
      },
      {
        label: 'Zwembad',
        value: 'swimmingPool',
      },
      {
        label: 'Airco',
        value: 'airConditioning',
      },
      {
        label: 'Elektrische vloerverwarming',
        value: 'underfloorHeating',
      },
      {
        label: 'Waterbed',
        value: 'waterBed',
      },
    ],
    refetchEnergy: true,
  },
  {
    key: 'houseConsumption',
    mutationVariable: 'consumption',
    question: 'Indien bekend, wat is het jaarlijks energieverbruik?',
    partnerQuestion: 'Indien bekend, wat is het jaarlijks energieverbruik?',
    type: CardType.CONSUMPTION,
    mutation: getMutation('UPDATE_CONSUMPTION'),
  },
  {
    key: 'energyPrices',
    mutationVariable: 'energyPrices',
    question: 'Indien bekend, wat zijn de huidige energieprijzen?',
    partnerQuestion: 'Indien bekend, wat zijn de huidige energieprijzen?',
    type: CardType.ENERGY_PRICE,
    mutation: getMutation('UPDATE_ENERGY_PRICES'),
  },
  {
    key: 'motivation',
    question: 'Wat is je voornaamste reden om energie te besparen?',
    type: CardType.RADIO,
    mutation: UPDATE_MOTIVATION,
    answerOptions: [
      {
        label: 'Geld besparen',
        value: 'financial',
      },
      {
        label: 'Wooncomfort verhogen',
        value: 'comfort',
      },
      {
        label: 'Uitstoot verminderen',
        value: 'ecological',
      },
      {
        label: 'Noodzakelijk onderhoud',
        value: 'maintenance',
      },
    ],
  },
  {
    key: 'greenEnergyContract',
    question: 'Zit je bij een groene energieleverancier?',
    explainer: 'Hiermee kunnen we betere inschattingen maken van de besparingen',
    type: CardType.RADIO,
    image: windmillImg,
    mutation: SET_EXPERIMENT_METADATA,
    answerOptions: [
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Ja',
        value: true,
      },
      {
        label: 'Weet ik niet',
        value: null,
      },
    ],
  },
  {
    key: 'referrer',
    question: 'Waardoor ben je op zoek gegaan naar energiebesparende oplossingen?',
    type: CardType.RADIO,
    mutation: SET_EXPERIMENT_METADATA,
    answerOptions: [
      {
        label: 'Familie of vrienden',
        value: 'familyFriends',
      },
      {
        label: 'Buren',
        value: 'neighbors',
      },
      {
        label: "Collega's of kennissen",
        value: 'acquaintances',
      },
      {
        label: 'Media',
        value: 'media',
      },
      {
        label: 'Overige',
        value: 'other',
      },
    ],
  },
  {
    key: 'final',
    type: CardType.FINAL,
  },
];

export default bcCards;
