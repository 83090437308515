import { gql, useMutation } from '@apollo/client';
import { Box, Button, Flex, Input, Modal, Select } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Small } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import React, { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react';
import { GET_USERLESS_HOUSE } from '../../queries/house';
import { getUserlessEnergyPricesVariables } from '../../types/generated/getUserlessEnergyPrices';
import { getUserlessHouse } from '../../types/generated/getUserlessHouse';
import {
  updateBasicHouseProperties_Userless,
  updateBasicHouseProperties_UserlessVariables,
} from '../../types/generated/updateBasicHouseProperties_Userless';
import fixUnit from '../../utils/fixUnit';

export const UPDATE_BASIC_HOUSE_PROPERTIES = gql`
  mutation updateBasicHouseProperties(
    $houseId: ID!
    $type: Int
    $area: Float
    $constructionYear: Int
  ) {
    updateHouseProps(
      houseId: $houseId
      type: $type
      area: $area
      constructionYear: $constructionYear
    ) {
      id
      area
      type
      constructionYear
    }
  }
`;

export const UPDATE_BASIC_HOUSE_PROPERTIES_USERLESS = gql`
  mutation updateBasicHouseProperties_Userless(
    $houseId: ID!
    $type: Int
    $area: Float
    $constructionYear: Int
  ) {
    updateHouseProps_Userless(
      houseId: $houseId
      type: $type
      area: $area
      constructionYear: $constructionYear
    ) {
      id
      area
      type
      constructionYear
    }
  }
`;

interface UpdateHouseInfoFormProps {
  area: number;
  houseId: string;
  type: number;
  constructionYear: number;
  toggleIsEditing: () => void;
  userlessHouseId?: string;
}

const UpdateHouseInfoForm: React.FC<UpdateHouseInfoFormProps> = ({
  houseId,
  area,
  type,
  constructionYear,
  toggleIsEditing,
  userlessHouseId,
}) => {
  const isMobile = useIsMobile();
  const [typeState, setType] = useState<string>(type >= 5 && type < 22 ? '5' : `${type}`);
  const [areaState, setArea] = useState<number>(area);
  const [constructionYearState, setConstructionYear] = useState<number>(constructionYear);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

  const [updateHouseProperties, { loading: updatingHouseProperties }] = useMutation(
    UPDATE_BASIC_HOUSE_PROPERTIES,
    { onCompleted: () => toggleIsEditing() },
  );

  const [updateHousePropertiesUserless, { loading: updatingHousePropertiesUserless }] = useMutation<
    updateBasicHouseProperties_Userless,
    updateBasicHouseProperties_UserlessVariables
  >(UPDATE_BASIC_HOUSE_PROPERTIES_USERLESS, {
    onCompleted: () => toggleIsEditing(),
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<getUserlessHouse, getUserlessEnergyPricesVariables>({
        query: GET_USERLESS_HOUSE,
      });
      if (data?.updateHouseProps_Userless) {
        const newData = {
          type: data.updateHouseProps_Userless.type,
          constructionYear: data.updateHouseProps_Userless.constructionYear,
          area: data.updateHouseProps_Userless.area,
        };
        cache.writeQuery({
          query: GET_USERLESS_HOUSE,
          data: { findUserlessHouse: { ...cachedData?.findUserlessHouse, ...newData } },
        });
      }
    },
  });

  const handleSetArea = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setArea(parseInt(e.target.value, 10));
  }, []);

  const handleSetType = useCallback((e: DropdownOption<string>) => {
    setType(e.value);
  }, []);

  const handleSetConstructionYear = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConstructionYear(parseInt(e.target.value, 10));
  }, []);

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setConfirmationModalOpen(true);
  }, []);

  const handleConfirm = () => {
    if (userlessHouseId) {
      return updateHousePropertiesUserless({
        variables: {
          houseId: userlessHouseId,
          constructionYear: constructionYearState,
          area: areaState,
          type: parseInt(typeState, 10),
        },
      });
    }
    return updateHouseProperties({
      variables: {
        houseId,
        constructionYear: constructionYearState,
        area: areaState,
        type: parseInt(typeState, 10),
      },
    });
  };

  const houseTypeOptions = useMemo(
    () => [
      {
        label: 'Vrijstaande woning',
        value: '1',
      },
      {
        label: 'Twee-onder-één-kapwoning',
        value: '2',
      },
      {
        label: 'Hoekwoning',
        value: '3',
      },
      {
        label: 'Tussenwoning',
        value: '4',
      },
      {
        label: 'Appartement',
        value: '5',
      },
    ],
    [],
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Flex flexWrap="wrap" mx="-3px">
          <Box width={[1]} px="3px">
            <Select<string>
              options={houseTypeOptions}
              fontSize={6}
              label="Woningtype"
              labelColor="grayDark"
              value={houseTypeOptions.find(option => option.value === typeState)}
              onChange={handleSetType}
            />
          </Box>
          <Box width={[1, 1 / 2]} px="3px">
            <Input
              min="1"
              max="9999"
              type="number"
              label="Woonoppervlak"
              value={areaState}
              onChange={handleSetArea}
              addonSide="end"
              addonContent={fixUnit('m2', true)}
            />
          </Box>
          <Box width={[1, 1 / 2]} px="3px">
            <Input
              min="1000"
              max={new Date().getFullYear()}
              type="number"
              label="Bouwjaar"
              value={constructionYearState}
              onChange={handleSetConstructionYear}
            />
          </Box>
          <Box width={[1, 1 / 3]} px="3px">
            <Button
              type="button"
              fluid
              fontSize={7}
              label="Annuleren"
              inverse
              bgColor="red"
              onClick={toggleIsEditing}
            />
          </Box>
          <Box width={[1, 2 / 3]} px="3px">
            <Button
              type="submit"
              fluid
              fontSize={7}
              loading={updatingHouseProperties || updatingHousePropertiesUserless}
              label="Opslaan"
            />
          </Box>
        </Flex>
      </form>
      <Modal
        mobile={isMobile}
        isOpen={confirmationModalOpen}
        title="Gegevens overschrijven"
        width="sm"
        buttons={[
          {
            label: 'Annuleren',
            inverse: true,
            bgColor: 'red',
            onClick: () => setConfirmationModalOpen(false),
          },
          { label: 'Ja, overschrijven', onClick: handleConfirm },
        ]}
      >
        <p>
          <Small>
            Weet je zeker dat je de gegevens afkomstig uit BAG/Kadaster wilt overschrijven? Dit kan
            niet ongedaan gemaakt worden en kan significante invloed op de uitkomsten hebben.
          </Small>
        </p>
      </Modal>
    </>
  );
};

export default UpdateHouseInfoForm;
