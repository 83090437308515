import { SavedAnswers } from '~/components/SavingsCheck/SavingsCheckModule';
import { initialQuestions_house } from '../../types/generated/initialQuestions';
import { ABTestEntry } from './../../hooks/useABTest';
import bcCards, { QuestionKey } from './getCards';

const filterCards = (
  house: Omit<initialQuestions_house, 'intake' | 'advice' | '__typename'> | null,
  savedAnswers: SavedAnswers,
  isEbvCheck: boolean,
  isPartnerCheck: boolean,
  inIFrame: boolean,
  abTestEntries: ABTestEntry[] | null,
  isExtendedCheck: boolean,
) => {
  if (!house) return bcCards;
  const isDEBBaseline =
    !abTestEntries ||
    abTestEntries?.length === 0 ||
    abTestEntries?.some(
      entry => entry.domain === 'resultsPage' && entry.variant === 'deb-baseline',
    );
  const isApartment = house.type > 4 && house.type < 22;
  const apartmentProperties = (savedAnswers.apartmentProperties as string[]) ?? [];
  const hasRoof = !isApartment || apartmentProperties.includes('hasRoof');
  const isOnGroundFloor = !isApartment || apartmentProperties.includes('isOnGroundFloor');
  const constructionYear = house.constructionYear;
  const currentYear = new Date().getFullYear();

  const houseHasWallCavity =
    constructionYear >= 1940 || house.walls.cavity || savedAnswers.hasWallCavity === true;
  const houseHasCrawlspace =
    isOnGroundFloor &&
    (constructionYear >= 1920 ||
      (house.crawlspace?.compartments && house.crawlspace?.compartments > 0) ||
      savedAnswers.hasCrawlspace === true);

  const isRelevant: Record<QuestionKey, boolean> = {
    // Partner check doesn't have an extra address card
    address: !isPartnerCheck && !inIFrame,

    // Only used in PartnerCheck atm, otherwise included in address card
    isVve: isPartnerCheck || inIFrame,

    // types 5 - 21 are apartments
    apartmentProperties: isApartment,

    // Extra question for mortgage route
    isCurrentOccupant: !isExtendedCheck && (isEbvCheck || isPartnerCheck),

    // Precursor question for wallInsulation
    // Only ask until 1940, assume there is one after
    hasWallCavity: constructionYear <= 1939 || !house.walls.cavity,

    // Base question for wallInsulation
    // Only ask between 1930 and 1975
    hasWallInsulation: houseHasWallCavity && constructionYear <= 1975,

    // Only when base question has been answered `true`
    hasWallInsulationFollowUp: savedAnswers.hasWallInsulation === true,

    // Replaces base insulation question (should never both be true)
    // Only ask between 1976 and 1999
    hasExtraWallInsulation:
      houseHasWallCavity && constructionYear >= 1976 && constructionYear <= 1999,

    // Precursor question for floorInsulation
    // Ask before 1920 or for apartments
    hasCrawlspace: isOnGroundFloor && (constructionYear <= 1919 || !house.crawlspace?.compartments),

    // Base question for floorInsulation
    // Only ask until 1983
    hasFloorInsulation: houseHasCrawlspace && constructionYear <= 1983,

    // Only when base question has been answered `true`
    hasFloorInsulationFollowUp: savedAnswers.hasFloorInsulation === true,

    // Replaces base insulation question (should never both be true)
    // Only ask between 1984 and 2014
    hasExtraFloorInsulation:
      houseHasCrawlspace && constructionYear >= 1984 && constructionYear <= 2014,

    // Base question for roofInsulation
    // Only ask until 1975
    hasRoofInsulation: hasRoof && constructionYear <= 1975,

    // Only when base question has been answered `true`
    hasRoofInsulationFollowUp: savedAnswers.hasRoofInsulation === true,

    // Replaces base insulation question (should never both be true)
    // Only ask between 1976 and 2017
    hasExtraRoofInsulation: hasRoof && constructionYear >= 1976 && constructionYear <= 2017,

    livingGlassType: true,
    sleepingGlassType: true,

    // Only ask about PV panels if there's a roof
    panelAmount: hasRoof,

    // Only ask if house construction year isn't more than 5 years ago
    // Is replaced by boilerInstallationYear in extended check
    boilerAge: !isExtendedCheck && currentYear - constructionYear >= 5,

    residentAmount: true,
    slurpers: true,
    houseConsumption: true,
    energyPrices: isPartnerCheck || inIFrame,
    motivation: !isPartnerCheck && !inIFrame,

    hasWeatherStripping: false, // Not really significant, leave out for now
    hasBath: false, // Not really significant, leave out for now
    hasDishWasher: false, // Not really significant, leave out for now

    // Only ask when in VU research experiment
    greenEnergyContract: !isDEBBaseline && !isPartnerCheck && !inIFrame,
    referrer: !isDEBBaseline && !isPartnerCheck && !inIFrame,

    final: !isPartnerCheck,
  };

  return bcCards.filter(card => isRelevant[card.key]);
};

export default filterCards;
